import { ApiUtil, readEntity, readLocal, clearApiState } from 'reactifi';

export function loadTrainingPeriods(organizationId, businessLine) {
  const filters = { 
    organization_id: organizationId, 
    business_lines: [businessLine],
    status: 'active' 
  };

  return readEntity('training_periods', null, filters, null, { number: 1, size: 100 });
}

export function readSystemMessages(userId) {
  return async dispatch => {
    const url = `/api/data/user_system_messages/${userId}.json`;
    const data = await ApiUtil.loadApiData(url, false);
    dispatch(readLocal(data));
  }
}

export function readTodoItems(notifiUrl, jwt, businessLine) {
  return async dispatch => {
    dispatch(clearApiState('todo_item'));
    const url = `${notifiUrl}/api/v1/todo_items.json?filter[business_line]=${businessLine}`;
    const data = await ApiUtil.loadExternalData(url, jwt, false);
    dispatch(readLocal(data));
  }
}

export function dismissTodoItem(todo, notifiUrl, jwt) {
  return async () => {
    const url = `${notifiUrl}/api/v1/todo_items/${todo.id}.json`;

    await ApiUtil.requestExternalData(url, jwt, false, 'PATCH', {
      body: JSON.stringify({
        data: {
          id: todo.id,
          type: 'todo_item',
          attributes: {
            is_read: true
          }
        }
      })
    });
  }
}

export function loadRuleSets(organizationId) {
  return readEntity('rule_sets', null, { organization_id: organizationId });
}
