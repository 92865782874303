import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Button, ExplainerText, Header, Section, useContainerProps, SearchComponent } from "reactifi";
import i18n from "lib/i18n";
import debounce from 'lodash/debounce';
import DownloadForm from './DownloadForm';
import DownloadsList from './DownloadsList';

const Downloads = props => {
  props = useContainerProps(props);

  const {
    activePage,
    activeSearch = '',
    activeSort,
    collection = [],
    currentBusinessLine,
    isAdmin,
    loadRuleSets,
    loadUploadedReports,
    organizationId,
    selectMember,
    selectOrgCapabilities,
    setActiveSearch
  } = props;

  let instructions, noData, title;

  if (isAdmin) {
    instructions = i18n.t('Add PDF and CSV reports to this organization. Primary admins will be able to see and download these reports.');
    noData = i18n.t('You don\'t have any reports available yet. Create one by clicking \"Add Report\" above.');
  } else {
    instructions = (
      <>
        {i18n.t('Impact Reports summarize the impact that EVERFI courses have on your learners during a given time period. For more info, please visit our')}
        <a href="https://help.everfi.com/s/article/Impact-Reports" target="_blank" rel="noopener noreferrer">{i18n.t('help site')}</a>.
      </>
    );
    noData = i18n.t('You don\'t have any reports available yet.');
    title = i18n.t('Impact Reports');
  }

  useEffect(() => {
    loadRuleSets(organizationId);
    selectOrgCapabilities(organizationId);
  }, []);

  useEffect(() => {
    if (activeSearch) loadCollectionOverride();
  }, [activeSearch]);

  const loadCollectionOverride = useMemo(
    () => debounce(() => {
      const query = activeSearch ? {
        name: {
          term: activeSearch,
          fields: {
            name: 'word_start'
          }
        }
      } : null;
  
      if (currentBusinessLine) {
        loadUploadedReports({ filters: { business_line: currentBusinessLine }, query }, organizationId);
      } else {
        loadUploadedReports({
          filters: null,
          sorts: activeSort,
          page: activeSearch.length > 0 ? 1 : activePage,
          query
        }, organizationId);
      }
    }, 350, { leading: false, trailing: true }),
    [activePage, activeSearch, activeSort, currentBusinessLine, organizationId]
  );

  const handleSearch = ({ searchValue }) => {
    setActiveSearch(searchValue);
  }

  return (
    <>
      {!isAdmin && <Header title={title} />}
      <Section>
        <ExplainerText>
          {instructions}
        </ExplainerText>
        {isAdmin && (
          <Button
            onClick={() => selectMember('add')}
            data-action="add-report"
            label={i18n.t('Add Report')}
            style="secondary"
          />
        )}
      </Section>

      <Section dataObjectType="impact-report-list" type="list" hideLoader={collection.length}>
        <div className="m-b-20">
          <SearchComponent
            placeholder={i18n.t('Search')}
            searchAction={handleSearch}
          />
        </div>
        <DownloadsList
          loadCollection={loadCollectionOverride}
          options={{ noDataText: noData }}
          currentBusinessLine={currentBusinessLine}
        />
        <DownloadForm />
      </Section>
    </>
  );
};

Downloads.propTypes = {
  activePage: PropTypes.number,
  activeSearch: PropTypes.string,
  activeSort: PropTypes.object,
  collection: PropTypes.array,
  currentBusinessLine: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isAdmin: PropTypes.bool,
  loadRuleSets: PropTypes.func,
  loadUploadedReports: PropTypes.func,
  organizationId: PropTypes.string,
  selectMember: PropTypes.func,
  selectOrgCapabilities: PropTypes.func,
  setActiveSearch: PropTypes.func
};

export default Downloads;
