import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ActionButtons, PagingDataList, Page, SearchComponent, Toolbar, AccessControlUtil } from 'reactifi';
import i18n from 'lib/i18n';
import OrganizationForm from './OrganizationForm';
import debounce from 'lodash/debounce';
import { Util } from "@everfi/reactifi";

class Organizations extends Component {
  static propTypes = {
    organizations: PropTypes.array,
    organizationId: PropTypes.string,
    currentEntity: PropTypes.object,
    createOrganization: PropTypes.func.isRequired,
    selectOrganization: PropTypes.func.isRequired,
    updateOrganization: PropTypes.func.isRequired,
    generateSlug: PropTypes.func,
    clearSlug: PropTypes.func,
    router: PropTypes.object,
    clearMessages: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    displayError: PropTypes.func.isRequired,
    findOrganizations: PropTypes.func.isRequired,
    isLoadingOrganizations: PropTypes.bool,
    viewer: PropTypes.string,
    orgMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    route: PropTypes.shape({
      permissions: PropTypes.arrayOf(PropTypes.string)
    }),
    activePage: PropTypes.object,
    activeSearch: PropTypes.string,
    activeSort: PropTypes.object,
    activeFilters: PropTypes.object,
    setActivePageAndFindOrganizations: PropTypes.func,
    setActiveSearchAndFindOrganizations: PropTypes.func,
    setActiveSortAndFindOrganizations: PropTypes.func,
    sort: PropTypes.object
  };

  static defaultProps = {
    organizations: []
  };

  constructor(props) {
    super(props);
    this.findOrganizations = debounce(this.findOrganizations.bind(this), 350, { leading: false, trailing: true });

    this.state = {
      organizationId: this.props.organizationId
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.organizationId !== state.organizationId) {
      return {
        organizationId: props.organizationId
      };
    }
    return null;
  }

  formatActive = (cell, row) => {
    if (row.redacted_at) {
      return (
        <>
          {i18n.t("Data redacted on {{ date }}", {
            date: Util.format(row.redacted_at, "MM/dd/yyyy")
          })}
        </>
      );
    }

    return cell ? <>{i18n.t('Active')}</> : <>{i18n.t('Inactive')}</>;
  }

  get actionConfig() {
    return [
      {
        icon: "eye",
        title: i18n.t('View'),
        tooltip: i18n.t('View Organization'),
        type: "view",
        actionType: "callToAction",
        action: this.viewAction
      }
    ];
  }

  get fields() {
    return [
      {
        name: 'short_id',
        title: i18n.t('ID'),
        canSort: true,
        width: 100
      },
      { name: 'name', title: i18n.t('Name'), canSort: true, defaultSort: true, width: "30%" },
      { name: 'active', title: i18n.t('Status'), width: 110, canSort: true, formatter: this.formatActive },
      { name: 'slug', title: i18n.t('Slug'), canSort: true, width: "17.5%" },
      { name: 'salesforce_id', title: i18n.t('Salesforce Alternative Acct Code'), canSort: true, width: "20%" },
      {
        name: 'users_count',
        title: i18n.t('Users'),
        canSort: false,
        width: 100
      },
      { name: 'actions', title: i18n.t('Actions'), width: 110,
        formatter: (_, row) => {
          return (
            row && (
              <ActionButtons
                row={row}
                buttons={this.actionConfig}
              />
            )
          );
        }
      }
    ];
  }

  canViewUsers = AccessControlUtil.hasPermission('view_users', this.props.route.permissions);

  get options() {
    return {
      onSortChange: this.onSortChange,
      sortName: this.props.sort?.value,
      sortOrder: this.props.sort?.direction,
      onRowClick: (_, row) => {
        this.props.router.push(`${row.id}/${this.canViewUsers ? 'users' : 'locations'}`);
      }
    };
  }

  findOrganizations = () => {
    this.props.findOrganizations(
      this.props.activeFilters,
      this.props.activePage,
      this.props.activeSort
    );
  }

  viewAction = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.router.push(`${row.id}/${this.canViewUsers ? 'users' : 'locations'}`);
  }

  onClose() {
    this.props.clearMessages();
  }

  onPageChange = page => {
    this.props.setActivePageAndFindOrganizations(page);
  }

  onSortChange = (sortName, sortOrder) => {
    if (sortName === this.props.activeSort?.sortName && sortOrder === this.props.activeSort?.sortOrder) {
      // only execute if sort values changed (avoid unnecessary requests)
      return;
    }

    this.props.setActiveSortAndFindOrganizations({ direction: sortOrder, value: sortName });
  }

  onSearch = filters => {
    this.props.setActiveSearchAndFindOrganizations(filters.searchValue);
  }

  actionButtonClick = () => {
    this.props.selectOrganization('add');
    this.setState({ showOrgModal: true });
  }

  render() {
    const actionButton = {
      label: i18n.t('Create Organization'),
      'data-action': 'create-organization',
      action: this.actionButtonClick,
      requiredPermission: 'create_organizations',
      permissions: this.props.route.permissions
    };

    return (
      <Page
        pageType="parent"
        contentType="list"
        title={i18n.t('Organizations')}
        actionButton={actionButton}
        errorMessage={this.props.currentEntity ? null : this.props.errorMessage}
        clearMessages={this.props.clearMessages}
        toolbar={
          <Toolbar toolbarClass="filters-toolbar">
            <SearchComponent
              value={this.props.activeSearch}
              placeholder={i18n.t('Search by ID, Unique ID, Name, Slug, Salesforce Alt Acct Code, Blackbaud Salesforce ID')}
              searchAction={this.onSearch}
              wrapperClass="full-width"
            />
          </Toolbar>
        }
        usePageWrapper={true}
      >
        {this.state.showOrgModal && (
          <OrganizationForm
            currentOrganization={this.props.currentEntity}
            selectOrganization={this.props.selectOrganization}
            createOrganization={this.props.createOrganization}
            updateOrganization={this.props.updateOrganization}
            generateSlug={this.props.generateSlug}
            clearSlug={this.props.clearSlug}
            clearMessages={this.props.clearMessages}
            displayError={this.props.displayError}
            errorMessage={this.props.errorMessage}
            viewer={this.props.viewer}
            router={this.props.router}
            permissions={this.props.route.permissions}
          />
        )}
        <div className="page-panel">
          <PagingDataList
            data={this.props.organizations}
            fields={this.fields}
            keyField="id"
            options={this.options}
            trClassName="table-row-pointer"
            isLoadingData={this.props.isLoadingOrganizations}
            onPageChange={this.onPageChange}
            totalCount={this.props.orgMeta ? this.props.orgMeta.total_count : 0}
            activePage={this.state.activePage}
            downloadAction={() => {}}
            closeTimeoutModal={() => {}}
          />
        </div>
      </Page>
    );
  }
}

export default Organizations;
