import React from 'react';
import PropTypes from 'prop-types';
import { RootProvider, AccessControlUtil } from 'reactifi';
import i18n from 'lib/i18n';

import {
  OrganizationsContainer,
  OrganizationContainer,
  OrganizationBrandingContainer,
  // OrganizationHealthContainer,
  OrganizationImpactStoriesContainer,
  OrganizationImpactStoryContainer,
  OrganizationUsersContainer,
  OrganizationUserFormContainer,
  OrganizationUserDetailsContainer,
  OrganizationLocationsContainer,
  OrganizationLocationsDetailsContainer,
  OrganizationUploadRolesContainer,
  OrganizationStyleGuideContainer,
  OrganizationUserChangelogContainer,
  OrganizationUserAssignmentContainer,
  OrganizationSettingsContainer
} from './containers';
import TrainingPeriodsContainer from '../TrainingPeriods/containers/TrainingPeriodsContainer';
import DownloadsContainer from '../Downloads/containers/DownloadsContainer';
import UploadConfirmationContainer from '../common/Components/upload/UploadConfirmationContainer';
import { DEFAULT_FILTERS } from './constants/userFilters';
import OrgNotFound from './OrgNotFound';
import createStore from './store/organizationsStore';
import { Router, Route, hashHistory, Redirect } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

let index = (props, _railsContext) => {
  const initialState = {
    api: {
      activeFilters: DEFAULT_FILTERS
    }
  };
  const store = createStore(initialState);
  const history = syncHistoryWithStore(hashHistory, store);

  /*
    tabList populated with defaults but can be expanded
    by using a static tab variable from an imported component.
  */
  const tabList = [
    {
      path: "users",
      component: OrganizationUsersContainer,
      googleMapsApiKey: _railsContext.googleMapsApiKey,
      viewer: props.viewer,
      jwt: props.jwt,
      contentServiceUrl: props.content_service_url,
      routeKey: 'users',
      title: i18n.t('Users'),
      requiredPermission: 'view_users'
    },{
      path: "locations",
      component: OrganizationLocationsContainer,
      googleMapsApiKey: _railsContext.googleMapsApiKey,
      viewer: props.viewer,
      title: i18n.t('Locations'),
      routeKey: 'locations'
    },{
      path: "branding",
      component: OrganizationBrandingContainer,
      routeKey: 'branding',
      title: i18n.t('Branding'),
      requiredPermission: 'manage_branding',
      children: [
        <Route path="add"
          component={OrganizationStyleGuideContainer}
          permissions={props.permissions}
          viewer={props.viewer} />,
        <Route path=":guide_id"
          component={OrganizationStyleGuideContainer}
          permissions={props.permissions}
          viewer={props.viewer} />
      ]
    },{
      path: "training",
      component: TrainingPeriodsContainer,
      routeKey: 'training',
      title: i18n.t('Training Period'),
      viewer: props.viewer,
      jwt: props.jwt,
      contentServiceUrl: props.content_service_url,
      requiredPermission: 'manage_training_periods'
    },{
      path: "downloads",
      component: DownloadsContainer,
      routeKey: 'downloads',
      title: i18n.t('Reports'),
      requiredPermission: 'upload_reports',
      viewer: props.viewer,
      jwt: props.jwt
    },{
      path: "impact_stories",
      component: OrganizationImpactStoriesContainer,
      routeKey: 'impact_stories',
      title: i18n.t('Impact Stories'),
      requiredPermission: 'manage_impact_stories',
      requiredCapability: 'Capabilities::ImpactDashboard',
      viewer: props.viewer,
      jwt: props.jwt
    }
  ];

  const externalChildRoutes = [];

  /*
    example tab on the index.js of the external component being imported.
    @required_prop is the name of the props item that will be fed in upon
    use in the consuming component
    @required_component is the component itself after it has been imported into
    the consuming component
    The other items are set in the index.js and not altered after import --
    for tab path, title etc.
    {
      component: {required_component: 'component'},
      content_service_url: {required_prop: 'content_service_url'},
      jwt: {required_prop: 'jwt'},
      adminifi_url: {required_prop: 'adminifi_url'},
      path: 'contentpackages',
      tabConfig: {
        title: 'Content Packages',
        path: '/contentpackages'
      }
    };
  */

  const createTabEntry = (ext) => {
    const component = window[ext.module][ext.name];
    const tab = component && component.tab;
    if (tab) {
      if (tab.childRoutes) {
        tab.childRoutes.forEach(name => {
          const childComponent = window[ext.module][name];
          if (childComponent && childComponent.childRoute) {
            externalChildRoutes.push(populateConfig(childComponent, childComponent.childRoute));
          }
        });
        delete tab.childRoutes;
      }
      tabList.push(populateConfig(component, component.tab));
    }
  };

  const populateConfig = (component, config) => {
    const populatedConfig = {};
    Object.entries(config).forEach(([key, value]) => {
      if (value.required_prop){
        populatedConfig[key] = props[value.required_prop];
      } else if(value.required_component){
        populatedConfig[key] = component;
      }
    });
    Object.assign(config, populatedConfig);
    return config;
  };

  const createTabs = () => {
    try {
      props.external_components.forEach((ext) => {
        createTabEntry(ext);
      });
    } catch (e) {
      let tabErrorMessage = 'Error caught when creating external component tabs. Make sure all services are running!';
      console.error(`${tabErrorMessage}\n${e}`);
    }
  };

  const canViewUsers = () => {
    if(props.permissions) {
      return AccessControlUtil.hasPermission('view_users', props.permissions);
    }
  }

  const canViewImpactStories = () => {
    if (props.permissions) {
      return AccessControlUtil.hasPermission('manage_impact_stories', props.permissions);
    }
  }

  const filterTabs = (tabList) => {
    if(!canViewUsers()) {
      return tabList.filter((tab) => tab.path !== 'users');
    }
    return tabList;
  }

  createTabs();

  const filteredTabs = filterTabs(tabList);

  return (
    <RootProvider store={store}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>

        <Route path="/"
          component={OrganizationsContainer}
          permissions={props.permissions}
          viewer={props.viewer}
          contentServiceUrl={props.content_service_url}
        />

        <Route
          path="/:id/settings"
          component={OrganizationSettingsContainer}
          jwt={props.jwt}
          viewer={props.viewer}
          contentServiceUrl={props.content_service_url}
          permissions={props.permissions}
        />

        {/* User detailed routes */}

        {canViewUsers() ? <Route
          path="/:id/users/:user_id/details"
          component={OrganizationUserDetailsContainer}
          googleMapsApiKey={_railsContext.googleMapsApiKey}
          jwt={props.jwt}
          viewer={props.viewer}
          certificateServiceUrl={props.certificate_service_url}
          contentServiceUrl={props.content_service_url}
          complianceUrl={props.compliance_url}
          permissions={props.permissions}
          singleUsePasswordHours={props.single_use_password_hours}
        /> : null}

        {canViewUsers() ? <Route
          path="/:id/users/:user_id/assignments/:user_assignment_id/progress/:progress_id"
          component={OrganizationUserAssignmentContainer}
          jwt={props.jwt}
          viewer={props.viewer}
          contentServiceUrl={props.content_service_url}
          permissions={props.permissions}
        /> : null}

        {canViewUsers() ? <Route
          path="/:id/users/:user_id/changelog"
          component={OrganizationUserChangelogContainer}
          jwt={props.jwt}
          permissions={props.permissions}
        /> : null}

        {canViewUsers() ? <Route
          path="/:id/users/:user_id"
          component={OrganizationUserFormContainer}
          googleMapsApiKey={_railsContext.googleMapsApiKey}
        /> : null}

        {/* Location routes */}
        <Route
          path="/:id/locations/:location_id/details"
          component={OrganizationLocationsDetailsContainer}
          googleMapsApiKey={_railsContext.googleMapsApiKey}
          jwt={props.jwt}
          contentServiceUrl={props.content_service_url}
          permissions={props.permissions}
        />

        {/* User role upload tool routes */}
        <Route
          path="/:id/upload_roles"
          component={OrganizationUploadRolesContainer}
          permissions={props.permissions}
        />

        <Route
          path="/:id/upload_roles/confirmation"
          component={UploadConfirmationContainer}
          permissions={props.permissions}
          left_text_button={i18n.t('Back to Users')}
          buildLeftButtonUrl={orgId => `/admin/organizations#/${orgId}/users`}
        />

        {/* Impact Story routes */}
        {canViewImpactStories() ? <Route
          path="/:id/impact_stories/:impact_story_id(/:impact_story_copy_id)"
          component={OrganizationImpactStoryContainer}
        /> : null}

        <>
          {externalChildRoutes.map((childRoute) => (
            <Route {...childRoute} permissions={props.permissions} key={childRoute.routeKey} />
          ))}
        </>

        {/* Organization tabs */}
        <Route path="/:id/" component={OrganizationContainer}
          permissions={props.permissions} >

          {/* Users tab routes console.log('index: ', filteredTabs)*/ }

          {filteredTabs.map((tab) => (<Route {...tab} permissions={props.permissions} key={tab.routeKey} />))}

          <Redirect from="*" to={canViewUsers() ? 'users': 'locations'} />
        </Route>
        <Route path="*" component={OrgNotFound} />
      </Router>
    </RootProvider>
  );
};

index.propTypes = {
  content_service_url: PropTypes.string,
  external_components: PropTypes.array,
  jwt: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.string),
  single_use_password_hours: PropTypes.number,
  viewer: PropTypes.string
};


export default index;
