import React from 'react';
import PropTypes from 'prop-types';
import {
  ValidationUtil,
  FieldGroup,
  RegistrationFormField,
  FloatingLabelFormField,
  RegistrationFieldGroup,
  SelectAsyncControl,
  AddressPickerControl,
  MaterialCheckbox,
  Button,
  FormSection
} from 'reactifi';
import i18n from 'lib/i18n';
import intersectionBy from 'lodash/intersectionBy';
import unionBy from 'lodash/unionBy';
import queryString from 'query-string';
import ManageBBIdProfile from '../../common/Components/ManageBBIdProfile';

export const LOGIN_OPTIONS = Object.freeze([
  { value: 'email', label: i18n.t('Use Email as Login (preferred)') },
  {
    value: 'username',
    label: i18n.t(
      'Use Username as Login (for users who do not have an email address)'
    )
  }
]);

export default class RegistrationBase extends React.Component {
  static propTypes = {
    bbIdEnabled: PropTypes.bool,
    bbIdRequired: PropTypes.bool,
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    errorMessages: PropTypes.array,
    className: PropTypes.string,
    clearMessages: PropTypes.func,
    isCreate: PropTypes.bool,
    isManager: PropTypes.bool,
    loadLocation: PropTypes.func.isRequired,
    googleMapsApiKey: PropTypes.string.isRequired,
    organizationId: PropTypes.string,
    user: PropTypes.object.isRequired,
    onValidityCheck: PropTypes.func,
    onFullFormFiledCheck: PropTypes.func,
    isSelfRegistered: PropTypes.bool,
    suppressionLists: PropTypes.array,
    suppressionListEmails: PropTypes.array,
    deleteFromSuppresionList: PropTypes.func
  };

  static defaultProps = {
    suppressionListEmails: []
  };

  constructor(props) {
    super(props);

    this.state = {
      user: this.props.user,
      changePassword:
        !this.props.data.metadata.is_change_password_checked.available,
      hasEmail:
        (this.props.isCreate &&
          (!this.props.data.metadata.username.available ||
            !(this.props.data.username || '').length)) ||
        !!(this.props.data.email || '').length,
      doesNotBelongToAnyLocation:
        this.props.data.metadata.does_not_belong_to_any_location.available &&
        !props.isCreate &&
        !props.data.location_id,
      errorMessages:
        this.props.user && this.props.user.bounced_email
          ? [
            {
              field_name: 'email',
              message: i18n.t("Please edit user's email")
            }
          ]
          : [],
      newPassword: null
    };

    if (this.state.hasEmail) {
      props.data.has_email_checked = 'email';
    }

    if (this.state.doesNotBelongToAnyLocation) {
      props.data.does_not_belong_to_any_location = true;
    }

    if (
      this.props.onValidityCheck &&
      this.props.onFullFormFiledCheck &&
      this.props.user &&
      this.requiredFields.every((field) => this.props.user[field] === '')
    ) {
      this.props.onValidityCheck(false);
      this.props.onFullFormFiledCheck(false);
    }
  }

  componentDidMount() {
    const { data } = this.props;
    if (data) {
      this.setState({
        user: this.requiredFields.reduce((newUser, field) => ({ 
          ...newUser, [field]: data[field]
        }), {})
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.errorMessages && props.errorMessages.length > 0) {
      // make sure error messages are always updated based on the new ones received, also taking into account the previous state
      const newErrors = intersectionBy(
        props.errorMessages,
        unionBy(props.errorMessages, state.errorMessages, 'field_name'),
        'field_name'
      );

      return { errorMessages: newErrors };
    }
    return null;
  }

  get requiredFields() {
    let required = [
      'first_name',
      'last_name',
      'current_password',
      'password',
      'password_confirmation',
      'location_id'
    ];

    if (this.isEmailRequired()) {
      required.push('email');
    }
    if (this.isRegistrationForUnder13()) {
      required.push('parent_email');
    }

    return required;
  }

  onChangePassword = () => {
    this.setState({ changePassword: !this.state.changePassword }, () => {
      if (!this.state.changePassword) {
        this.props.data.is_change_password_checked = 'false';
        this.props.data.password = '';
        this.props.data.password_confirmation = '';
        this.props.data.current_password = '';
      } else {
        this.props.data.is_change_password_checked = 'true';
      }
    });
  };

  onChangeHasEmail = (value) => {
    this.setState({ hasEmail: value === 'email' }, () => {
      if (this.state.hasEmail) {
        this.props.data.has_email_checked = 'true';
        this.props.data.username = null;
        this.props.data.password = null;
        this.props.data.password_confirmation = null;
      } else {
        this.props.data.has_email_checked = 'false';
        this.props.data.email = null;
      }
    });
  };

  clearAddressFields = () => {
    if (!this.state.doesNotBelongToAnyLocation) {
      this.props.data.work_address_room = null;
      this.props.data.work_address_formatted = null;
      AddressPickerControl.addressPropVals.forEach((prop) => {
        this.props.data[`work_address${prop}`] = null;
      });
    }
  };

  onToggleNoLocation = () => {
    this.clearAddressFields();
    this.setState(
      {
        doesNotBelongToAnyLocation: !this.state.doesNotBelongToAnyLocation
      },
      () => {
        if (this.state.doesNotBelongToAnyLocation) {
          this.props.data.location_id = null;
          this.props.data.location_address_formatted = null;
        } else {
          this.clearAddressFields();
        }
      }
    );
  };

  onLocationChange = (location) => {
    if (!location?.id) return;

    this.props.loadLocation(location.id, this.props.organizationId);
    this.props.data.doesNotBelongToAnyLocation = false;
    this.setState({ doesNotBelongToAnyLocation: false });
  };

  formatAddressPropName = (prop) => {
    return `[${this.props.index}][${prop}]`;
  };

  getInitialLocationData = () => {
    const { user } = this.state;
    const { data: { location_id, location_name } = {} } = this.props;
    const locationId = user?.location?.id || location_id;
    const locationName = user?.location?.name || location_name;

    return locationId && locationName ? { id: locationId, display: locationName } : null;
  };

  buildDisplay = (i) => {
    return i.name;
  };

  isEmailRequired = () => {
    // if creating, email is required when email is selected as login option
    if (this.props.isCreate) {
      return this.state.hasEmail;
    }

    // if editing, email is required when there's no username
    return !this.props.data.username;
  };

  isRegistrationForUnder13 = () => {
    let params = queryString.parse(window.location.search);
    return params && params.under_13 === 'true';
  };

  isRegistrationForPiiRestrictedNextLearner = () => {
    if (this.props.data.rule_set_roles) {
      return Object.values(this.props.data.rule_set_roles).includes(
        'pii_restricted_next_learner'
      );
    } else {
      return false;
    }
  };

  isPiiSensitiveRegistration = () => {
    return (
      this.isRegistrationForUnder13() ||
      this.isRegistrationForPiiRestrictedNextLearner()
    );
  };

  onNewPasswordChange = (newPassword) => {
    this.setState({ newPassword });
  };

  passwordHintText = () => {
    return (
      <>
        <b>{i18n.t('Password Requirements:')}</b>
        <ul>
          <li>{i18n.t('Must contain at least 12 characters')}</li>
          <li>
            {i18n.t(
              'Must contain 3 of 4 characters: upper case, lower case, number, special character (!@#S%*)'
            )}
          </li>
          <li>
            {i18n.t(
              'Must never include personal information or the word “password”'
            )}
          </li>
          <li>
            {i18n.t('Must never contain an indicator of time (ex. Winter24)')}
          </li>
          <li>{i18n.t('Cannot use previous 6 passwords')}</li>
        </ul>
      </>
    );
  };

  // eslint-disable-next-line complexity
  displayLoginFields = () => {
    const {
      bbIdEnabled,
      bbIdRequired,
      isCreate,
      isManager,
      isSelfRegistered,
      data
    } = this.props;
    const { hasEmail, changePassword, newPassword } = this.state;
    const showAltLoginSpecificFields = !isCreate || !hasEmail;
    const displayEditCustomInfo = !isCreate && !!data && !!data.username;
    const emailHintText = displayEditCustomInfo
      ? i18n.t(
        'This email is used for password recovery, or to send invitations/reminders about your training.'
      )
      : i18n.t('Email will be used for password recovery and communication.');
    const usernameHintText = displayEditCustomInfo
      ? null
      : i18n.t(
        'Choose a unique username. You use letters, numbers, dot and hyphen only.'
      );
    const allowChangingPasswordFields =
      !bbIdRequired || (bbIdRequired && !isManager);

    return (
      <FieldGroup>
        {displayEditCustomInfo && (
          <FieldGroup>
            <RegistrationFormField key="email" className="grid-col-span-full">
              <h5>{i18n.t('Either an Email or a Username is required')}</h5>
            </RegistrationFormField>
          </FieldGroup>
        )}
        {(!isCreate || hasEmail) && (
          <FieldGroup>
            <RegistrationFormField key="email" className="grid-col-span-full">
              <FloatingLabelFormField
                type="Email"
                caption={i18n.t('Email Address')}
                required={this.isEmailRequired()}
                hintText={emailHintText}
              />
            </RegistrationFormField>
            {bbIdEnabled && isManager && (
              <RegistrationFormField key="email">
                <ManageBBIdProfile
                  bbIdEnabled={bbIdEnabled}
                  isManager={isManager}
                />
              </RegistrationFormField>
            )}
          </FieldGroup>
        )}
        {allowChangingPasswordFields &&
          (showAltLoginSpecificFields ||
            changePassword ||
            this.isPiiSensitiveRegistration()) && (
          <FieldGroup>
            {(showAltLoginSpecificFields ||
                this.isPiiSensitiveRegistration()) && (
              <FieldGroup>
                <RegistrationFormField
                  key="username"
                  className="grid-col-span-full reg-username"
                >
                  <FloatingLabelFormField
                    type="Username"
                    caption={i18n.t('Username')}
                    required={!hasEmail || this.isPiiSensitiveRegistration()}
                    hintText={usernameHintText}
                    tooltip={i18n.t(
                      'Your username should be a word or phrase you can easily remember. You should not use your name, your address or zip code,  your phone number, your school name or any information that helps someone identify you.'
                    )}
                    tooltipPosition="top"
                  />
                </RegistrationFormField>
              </FieldGroup>
            )}
            {
              <FieldGroup>
                <RegistrationFormField
                  key="parent_email"
                  className="grid-col-span-full"
                >
                  <FloatingLabelFormField
                    type="email"
                    caption={i18n.t('Parent or Guardian Email')}
                    required={true}
                    hintText={i18n.t(
                      'Email will be used for password recovery'
                    )}
                  />
                </RegistrationFormField>
              </FieldGroup>
            }
            <RegistrationFormField
              key="is_change_password_checked"
              className="form-group"
            >
              <Button
                style="secondary"
                className="m-l-0"
                branded={true}
                onClick={this.onChangePassword}
              >
                <span>{i18n.t('Change Password')}</span>
              </Button>
            </RegistrationFormField>
            {((isCreate && !hasEmail) || changePassword) && (
              <FieldGroup>
                {changePassword && (
                  <RegistrationFormField key="current_password">
                    <FloatingLabelFormField
                      type="password"
                      caption={i18n.t('Current Password')}
                      required={true}
                      pattern=".*"
                    />
                  </RegistrationFormField>
                )}
                {(showAltLoginSpecificFields || isSelfRegistered) && (
                  <RegistrationFormField
                    key="password"
                    className={isCreate && !hasEmail && 'grid-col-span-full'}
                  >
                    <FloatingLabelFormField
                      caption={i18n.t('New Password')}
                      hintText={this.passwordHintText()}
                      pattern=".*"
                      required={true}
                      tooltip={
                        isCreate &&
                          !hasEmail &&
                          i18n.t(
                            "Learners are prompted to change their password upon login for the first time. Updating a user's password will require them to change their password the next time they log in."
                          )
                      }
                      tooltipPosition="top"
                      type="password"
                      onChange={this.onNewPasswordChange}
                    />
                  </RegistrationFormField>
                )}
                <RegistrationFormField key="password_confirmation">
                  <FloatingLabelFormField
                    caption={i18n.t('Password Confirmation')}
                    pattern={
                      newPassword?.length
                        ? `^${newPassword.replace(
                          /[/\-\\^$*+?.()|[\]{}]/g,
                          '\\$&'
                        )}$`
                        : null
                    }
                    required={true}
                    type="password"
                    validationType={
                      newPassword?.length ? 'password_confirmation' : null
                    }
                  />
                </RegistrationFormField>
              </FieldGroup>
            )}
          </FieldGroup>
        )}
      </FieldGroup>
    );
  };

  onLocationBlur = (val) => {
    let newErrorMessages =
      this.state.errorMessages && this.state.errorMessages.length > 0
        ? this.state.errorMessages
        : [];
    if (val === null) {
      if (
        newErrorMessages.findIndex(
          (error) => error.field_name === 'location_id'
        ) === -1
      ) {
        newErrorMessages.push({
          field_name: 'location_id',
          message: i18n.t('Location needed for proper training and reporting.')
        });
      }
    } else {
      newErrorMessages.filter((error) => error.field_name !== 'location_id');
    }
    this.setState({
      errorMessages: newErrorMessages
    });
  };

  doesNotBelongToAnyLocationField = () => {
    return (
      <RegistrationFormField
        key="does_not_belong_to_any_location"
        className="form-group"
      >
        <MaterialCheckbox
          name="does_not_belong_to_any_location"
          ariaLabel={i18n.t(
            'Unselecting this checkbox will change both Address lines to an editable Locations field'
          )}
          onChange={this.onToggleNoLocation}
        >
          {i18n.t("I don't work at any of the locations listed.")}
        </MaterialCheckbox>
      </RegistrationFormField>
    );
  };

  displayLocationFields = () => {
    const queryFields = {
      external_id: 'word_start',
      name: 'word_start',
      street_number: 'word_start',
      route: 'word_start',
      city: 'word_start',
      state: 'word_start',
      zip: 'word_start'
    };
    if (!this.state.doesNotBelongToAnyLocation) {
      return (
        <FieldGroup>
          <RegistrationFormField
            key="location_id"
            className="grid-col-span-full"
          >
            <FloatingLabelFormField
              caption={i18n.t('Location')}
              className="col-half-width"
              name="location_id"
              required={true}
              srHelpText={i18n.t(
                'Only the first 20 results are displayed, type ahead for more options.'
              )}
              tooltip={i18n.t(
                'These location options can be set on the Manage Locations page in the sidebar.'
              )}
              tooltipPosition="top"
            >
              <SelectAsyncControl
                buildDisplay={this.buildDisplay}
                dataUrl="/api/data/locations.json"
                displayProperty="name"
                filters={{ organization_id: this.props.organizationId }}
                initialData={this.getInitialLocationData()}
                multi={false}
                onBlur={this.onLocationBlur}
                onChange={this.onLocationChange}
                pageSize={100}
                placeholder=""
                queryFields={queryFields}
                sortBy="name"
              />
            </FloatingLabelFormField>
          </RegistrationFormField>
          {this.doesNotBelongToAnyLocationField()}
        </FieldGroup>
      );
    } else {
      return (
        <FieldGroup>
          <RegistrationFormField key="work_address">
            <FloatingLabelFormField
              caption={i18n.t('Address (Type and select below)')}
              className="col-half-width"
            >
              <AddressPickerControl
                googleMapsApiKey={this.props.googleMapsApiKey}
                initialData={this.props.data}
                formatAddressPropName={this.formatAddressPropName}
                placeholder=""
                addressFieldPrefix="work_address"
              />
            </FloatingLabelFormField>
          </RegistrationFormField>
          <RegistrationFormField key="work_address_room">
            <FloatingLabelFormField
              caption={i18n.t('Address Line 2')}
              className="col-half-width"
            />
          </RegistrationFormField>
          {this.doesNotBelongToAnyLocationField()}
        </FieldGroup>
      );
    }
  };

  onValueChange = (event) => {
    const partialKey = Object.keys(event)[0].match(/\[([\D][\w]+)\]/g)[0];
    const key = partialKey.substring(1, partialKey.length - 1);
    const value = Object.values(event)[0];
    let newErrorMessages =
      this.state.errorMessages && this.state.errorMessages.length > 0
        ? this.state.errorMessages.slice(0)
        : [];

    if (!this.requiredFields.includes(key)) {
      this.validate();
      return;
    }

    newErrorMessages = this.state.errorMessages.filter(
      (item) => item.field_name !== key
    );
    if (value === '') {
      newErrorMessages.push({
        field_name: key,
        message: i18n.t('This field is required')
      });
    } else if (key === 'email' || key === 'parent_email') {
      this.props.clearMessages();
      let pattern = new RegExp(ValidationUtil.GetValidationPattern('email'));
      if (!pattern.test(value)) {
        newErrorMessages.push({
          field_name: key,
          message: i18n.t('The email you entered is invalid')
        });
      }
    } else if (key === 'password' || key === 'password_confirmation') {
      this.props.clearMessages();
      newErrorMessages = newErrorMessages.filter(
        (errorMessage) => !errorMessage.field_name === key
      );
    }

    this.setState(
      {
        user: { ...this.state.user, [key]: value },
        errorMessages: newErrorMessages
      },
      this.validate
    );
  };

  validate = () => {
    const { user } = this.state;
    const { onValidityCheck } = this.props;
    const { onFullFormFiledCheck } = this.props;

    if (onValidityCheck) {
      const valid = !this.requiredFields.some(
        (field) => user && user?.[field] === ''
      );

      onValidityCheck(valid);
    }

    if (onFullFormFiledCheck) {
      let fieldCount = 0;
      const filteredFields = this.requiredFields.filter(field => document.querySelector(`input[fieldname="${field}"]`));
      filteredFields.forEach(field => {
        if (user && user[field] && user[field] !== ''){
          fieldCount++;
        }
      });
      onFullFormFiledCheck(filteredFields.length === fieldCount);
    }
  };

  displayInvitationFields = () => {
    if (this.props.isCreate && this.state.hasEmail) {
      return (
        <RegistrationFormField key="is_invited">
          <MaterialCheckbox name="is_invited">
            {i18n.t('Send invitation to user.')}
          </MaterialCheckbox>
        </RegistrationFormField>
      );
    }
  };

  displayCheckboxFields = () => {
    const { isSelfRegistered } = this.props;

    return (
      <FieldGroup>
        <RegistrationFormField
          key="editable_fields"
          className="grid-col-new-span"
        >
          <MaterialCheckbox name="editable_fields">
            {i18n.t('User can edit their personal information?')}
          </MaterialCheckbox>
        </RegistrationFormField>
        {!isSelfRegistered && (
          <RegistrationFormField key="suppress_invites">
            <MaterialCheckbox name="suppress_invites">
              {i18n.t('Do not send training invitations')}
            </MaterialCheckbox>
          </RegistrationFormField>
        )}
        <RegistrationFormField key="active">
          <MaterialCheckbox name="active">
            {i18n.t('User account active')}
          </MaterialCheckbox>
        </RegistrationFormField>
        {!isSelfRegistered && (
          <RegistrationFormField key="suppress_reminders">
            <MaterialCheckbox name="suppress_reminders">
              {i18n.t('Do not send training reminders')}
            </MaterialCheckbox>
          </RegistrationFormField>
        )}
        <RegistrationFormField key="opt_out_of_marketing">
          <MaterialCheckbox name="opt_out_of_marketing">
            {i18n.t('Opt out of targeted ads')}
          </MaterialCheckbox>
        </RegistrationFormField>
        <RegistrationFormField key="opt_out_of_sales_data">
          <MaterialCheckbox name="opt_out_of_sales_data">
            {i18n.t('Opt out of sale of data')}
          </MaterialCheckbox>
        </RegistrationFormField>
      </FieldGroup>
    );
  };

  handleSuppressionListClick = (row, checked) => {
    this.props.deleteFromSuppresionList(row.id, checked);
    this.validate();
  };

  getMetadata = () => {
    const { bbIdEnabled, data, isManager } = this.props;

    if (!isManager) {
      // for learners, return normal metadata
      return data.metadata;
    }

    // for managers, restrict email edit if BBID is enabled
    return data.metadata
      ? {
        ...data.metadata,
        email: { ...data.metadata.email, editable: !bbIdEnabled }
      }
      : null;
  };

  render() {
    const metadata = this.getMetadata();
    const showNotifications = !!this.props.suppressionListEmails.length;

    if (!metadata) {
      return null;
    }

    return (
      <>
        <FieldGroup
          {...this.props}
          onValueChange={(e) => {
            this.onValueChange(e);
          }}
        >
          <RegistrationFieldGroup
            index={this.props.index}
            metadata={metadata}
            errorMessages={this.state.errorMessages}
            className="registration-component-modal-grid"
          >
            <RegistrationFormField key="first_name">
              <FloatingLabelFormField
                caption={i18n.t('First Name')}
                required={true}
                autoFocus={true}
              />
            </RegistrationFormField>
            <RegistrationFormField key="last_name">
              <FloatingLabelFormField
                caption={
                  !this.isPiiSensitiveRegistration()
                    ? i18n.t('Last Name')
                    : i18n.t('Last Initial')
                }
                required={true}
                maxLength={this.isPiiSensitiveRegistration() ? 1 : 99}
              />
            </RegistrationFormField>
            {this.displayLoginFields()}
            <RegistrationFormField key="employee_id">
              <FloatingLabelFormField caption={i18n.t('Employee ID')} />
            </RegistrationFormField>
            <RegistrationFormField key="student_id">
              <FloatingLabelFormField caption={i18n.t('Student ID')} />
            </RegistrationFormField>
            {this.displayLocationFields()}
            <RegistrationFormField key="sso_id">
              <FloatingLabelFormField
                caption={i18n.t('SSO ID')}
                tooltip={i18n.t(
                  'For organizations using Single Sign-on, the SSO ID validates a user when they attempt to login with SSO'
                )}
                tooltipPosition="top"
              />
            </RegistrationFormField>
            {this.displayInvitationFields()}
            {this.displayCheckboxFields()}
          </RegistrationFieldGroup>
        </FieldGroup>
        {showNotifications && (
          <FormSection title={i18n.t('Notification Preferences')}>
            {this.props.suppressionListEmails?.map((row) => (
              <FieldGroup>
                <MaterialCheckbox
                  inline={false}
                  onChange={(checked) => {
                    this.handleSuppressionListClick(row, checked);
                  }}
                >
                  {i18n.t('Opt back into')}{' '}
                  {
                    this.props.suppressionLists?.find(
                      (item) =>
                        item.id.toString() ===
                        row.suppression_list_id.toString()
                    )?.name
                  }
                </MaterialCheckbox>
              </FieldGroup>
            ))}
          </FormSection>
        )}
      </>
    );
  }
}
