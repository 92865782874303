import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ModalForm, FieldGroup, FloatingLabelFormField, MaterialCheckbox, StringUtil, SelectControl, AccessControlWrapper, Button } from 'reactifi';
import i18n from 'lib/i18n';
import ORG_TYPES from '../constants/organizationTypes';

const K12_ORG_FLAG = 'k12_organization';

export default class OrganizationForm extends Component {
  static propTypes = {
    clearSlug: PropTypes.func,
    clearMessages: PropTypes.func.isRequired,
    createOrganization: PropTypes.func.isRequired,
    currentOrganization: PropTypes.object,
    errorMessage: PropTypes.string,
    generateSlug: PropTypes.func,
    permissions: PropTypes.arrayOf(PropTypes.string),
    router: PropTypes.object,
    selectOrganization: PropTypes.func.isRequired,
    updateOrganization: PropTypes.func.isRequired,
    viewer: PropTypes.string
  };

  static defaultProps = {
    currentOrganization: null
  };

  constructor(props) {
    super(props);
    this.state = {
      demo: props.currentOrganization.demo,
      orgName: null
    };
  }

  onCancel = () => {
    this.props.clearSlug();
    this.props.clearMessages();
    this.props.selectOrganization(null);
  }

  onToggleDemo = (demo) => {
    this.setState({ demo });
  };

  onNameChange = (value) => {
    this.setState({
      orgName: value
    });
  }

  onGenerateSlug = () => {
    let slug = this.state.orgName || '';
    slug = StringUtil.Slugify(slug);
    this.props.generateSlug(slug);
    this.setState({
      slugButtonClicked: true
    });
  };

  onSlugChange = (value) => {
    const { clearSlug, generateSlug } = this.props;
    if (value) {
      generateSlug(value);
    } else {
      clearSlug();
    }
  }

  createOrganization = async (organization, addAnother) => {
    const newOrg = await this.props.createOrganization(organization, addAnother);
    if (newOrg) {
      this.props.router.push(`${newOrg.data.id}/capabilities`);
    }
  };

  render() {
    let modalTitle = i18n.t('New Organization');
    if (this.props.currentOrganization && this.props.currentOrganization.id) {
      modalTitle = i18n.t('Edit %s', {
        postProcess: 'sprintf',
        sprintf: [this.props.currentOrganization.name]
      });
    }

    return (
      <ModalForm
        data={this.props.currentOrganization}
        errorMessage={this.props.errorMessage}
        title={modalTitle}
        saveButtonText={i18n.t('Create Organization')}
        saveAndAddButton={false}
        onCancel={() => this.onCancel()}
        addAction={this.createOrganization}
        updateAction={this.props.updateOrganization}
      >
        {this.props.viewer === 'admin' && (
          <FieldGroup className="row m-b-20">
            <FieldGroup className="col-12">
              <MaterialCheckbox className="org-demo-checkbox" name="demo" onChange={this.onToggleDemo}>
                {i18n.t('This is a demo account')}
              </MaterialCheckbox>
            </FieldGroup>
          </FieldGroup>
        )}
        <FieldGroup className="container-fluid">
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                name="name"
                className="org-name"
                caption={i18n.t('Organization Name')}
                required={true}
                onChange={this.onNameChange}
              />
            </FieldGroup>
          </FieldGroup>
          <AccessControlWrapper flag={K12_ORG_FLAG} requiredPermission="set_organization_type" permissions={this.props.permissions}>
            <FieldGroup className="row">
              <FloatingLabelFormField 
                className="col-12" 
                caption={i18n.t('Organization Type')} 
                name="organization_type_id"
                required={true}
              >
                <SelectControl options={ORG_TYPES} valueKey="id" labelKey="label" />
              </FloatingLabelFormField>
            </FieldGroup>
          </AccessControlWrapper>
          {!this.state.demo && (
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  name="salesforce_id"
                  caption={i18n.t('Salesforce Alternative Acct Code')}
                  required={true}
                />
              </FieldGroup>
            </FieldGroup>
          )}
          {!this.state.demo && (
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  name="bb_salesforce_id"
                  caption={i18n.t('Blackbaud Salesforce ID')}
                  required={true}
                />
              </FieldGroup>
            </FieldGroup>
          )}
          <FieldGroup className="row">
            <FieldGroup className="col-8">
              <FloatingLabelFormField
                name="slug"
                caption={i18n.t('Slug')}
                required={true}
                valueOverride={true}
                onChange={this.onSlugChange}
                runValidation={this.state.slugButtonClicked}
                hintText={i18n.t('This will show up in all URLs and should identify the organization.')}
              />
            </FieldGroup>
            <FieldGroup className="col-4 text-end">
              <Button
                label={i18n.t('Generate Slug')}
                data-action="generate-slug"
                style="secondary"
                className="m-t-10"
                onClick={this.onGenerateSlug}
              />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <MaterialCheckbox name="editable_fields">
                {i18n.t('Allow users to edit their personal information.')}
              </MaterialCheckbox>
            </FieldGroup>
          </FieldGroup>
        </FieldGroup>
        <hr />
        <div className="row">
          <div className="col-6">
            <div>{i18n.t('Once you create an organization:')}</div>
            <ol className="p-l-15">
              <li>{i18n.t('Set capabilities')}</li>
              <li>{i18n.t('Add Content')}</li>
              <li>{i18n.t('Add a location')}</li>
              <li>{i18n.t('Add an Admin')}</li>
            </ol>
          </div>
        </div>
      </ModalForm>
    );
  }
}
